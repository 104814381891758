import {useMemo} from "react";
import {useParams} from "react-router-dom";
import useDataTypeFormatter from "../useDataTypeFormatter";
import {getOrderBankDetails, getOrderStaffDetails, getOrderValueDetails} from "../../../utils/orders";
import {handleMainAddress} from "../../../services/customerServices/customerServiceStructure";


const useSingleGroupOrderData = (allOrdersData = []) => {
	allOrdersData = allOrdersData.filter(order => order?.return_type !== "order")
	const {groupOrderId} = useParams()
	const {currencyFormatter, dateFormatter, dateOnlyFormatter, timeFormatter} = useDataTypeFormatter()

	return useMemo(() => {
		const { banks, staff, customer = {}, customer_user = {} } = allOrdersData?.[0] || {}

		const bankDetails = getOrderBankDetails(banks)
		const staffDetails = getOrderStaffDetails(staff)
		const {
			summary,
			orderDetails
		} = getOrderValueDetails(allOrdersData, currencyFormatter, dateFormatter, dateOnlyFormatter, timeFormatter) || {}

		const customerDetails = {
			...(customer || {}),
			id: customer?.id || customer_user?.id,
			firstName: customer?.first_name || customer_user?.first_name,
			fullName: `${customer?.first_name || customer_user?.first_name} ${customer?.last_name || customer_user?.last_name}`,
			email: customer?.email ||  customer_user?.email,
			phone: customer?.phone ||  customer_user?.phone,
			address: handleMainAddress(customer?.address || allOrdersData?.[0]?.delivery_address),
			...(!!customer_user?.id && { isStoreFront: true })
		}

		return { bankDetails, staffDetails, customerDetails, orderDetails, summary }
	}, [groupOrderId, allOrdersData])
}

export default useSingleGroupOrderData