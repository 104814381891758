import {CheckBox, Input, Select} from "../index";
import {FlexColumnStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {Fragment, useEffect, useState} from "react";
import {SplitOrPartialPaymentStyled} from "../../styles/formStyles";
import {Heading} from "../../styles/textStyles";
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";
import {useFormikContext} from "formik";
import {useBankQuery} from "../../hooks/useServices/useBankServices";


const defaultShowPaymentValue = {
  split_cash_active: false,
  split_transfer_active: false,
  split_pos_active: false,
  split_wallet_active: false
}


const SplitOrPartialPayment = ({totalPayable, customer = {}, isBulkDebitPay}) => {
  const {data: allBanks} = useBankQuery()
  const {setFieldValue} = useFormikContext()
  const {currencyFormatter} = useDataTypeFormatter()
  const [paymentValue, setPaymentValue] = useState({
    split_cash_value: 0,
    split_transfer_value: 0,
    split_pos_value: 0,
    split_wallet_value: 0
  })
  
  const [{
    split_cash_active,
    split_pos_active,
    split_transfer_active,
    split_wallet_active
  }, setShowPayment] = useState(defaultShowPaymentValue)
  
  const [activePaymentTypeForBulkPay, setActivePaymentTypeForBulkPay] = useState("")

  const handleShowPayment = (value, type) => {
    
    if (!isBulkDebitPay) {
      setShowPayment(prevState => ({
        ...prevState,
        [type]: value
      }))
    }
    
    // if bulk debit payment and a payment method is selected, disable other payment methods
    // only one payment method can be used when doing a customer bulk partial payment
    if (isBulkDebitPay) {
      setActivePaymentTypeForBulkPay(type)
      setShowPayment(() => ({
        ...defaultShowPaymentValue,
        [type]: value
      }))
    }
    
    if ((type === "split_cash_active" && !value) || (type !== "split_cash_active" && isBulkDebitPay)) {
      setFieldValue("split_cash_value", 0)
      setFieldValue("split_cash_value_format", "")
      setPaymentValue(prevState => ({ ...prevState, split_cash_value: 0 }))
    }
    
    if ((type === "split_transfer_active" && !value) || (type !== "split_transfer_active" && isBulkDebitPay)) {
      setFieldValue("split_transfer_value", 0)
      setFieldValue("split_transfer_value", 0)
      setFieldValue("split_transfer_bank", "")
      setFieldValue("split_transfer_value_format", "")
      setPaymentValue(prevState => ({...prevState, split_transfer_value: 0}))
    }
    
    if ((type === "split_pos_active" && !value) || (type !== "split_pos_active" && isBulkDebitPay)) {
      setFieldValue("split_pos_value", 0)
      setFieldValue("split_pos_bank", "")
      setFieldValue("split_pos_value_format", "")
      setPaymentValue(prevState => ({ ...prevState, split_pos_value: 0 }))
    }
    
    if ((type === "split_wallet_active" && !value) || (type !== "split_wallet_active" && isBulkDebitPay)) {
      setFieldValue("split_wallet_value", 0)
      setFieldValue("split_wallet_value_format", "")
      setPaymentValue(prevState => ({...prevState, split_wallet_value: 0}))
    }
  }

  const handleChange = (value, type) => {
    setPaymentValue(prevState => ({
      ...prevState,
      [type]: value
    }))
  }

  const getAmountPaying = () => {
    const {split_transfer_value, split_pos_value, split_cash_value, split_wallet_value} = paymentValue
    
    return [split_transfer_value, split_pos_value, split_cash_value, split_wallet_value].reduce((prev, curr) => {
      if (Number(Number(curr).toFixed(2))) {
        return prev + Number(Number(curr).toFixed(2))
      }
  
      return prev
    }, 0)
  }

  useEffect(() => {
    const amountPaying = getAmountPaying()
    setFieldValue("splitOrPartialPaymentAmount", amountPaying)
  }, [paymentValue])
  
  return (
    <SplitOrPartialPaymentStyled>
      
      {isBulkDebitPay && (
        <Heading style={{marginBottom: "0.6rem"}} size={0.8} color="error">You can only select one payment method when
          doing a customer bulk debt
          payment</Heading>
      )}
      
      {/*{Number(customer?.account_balance || 0) > 0 && (*/}
      {/*  <FlexColumnStyled>*/}
      {/*    <CheckBox checked={isBulkDebitPay && activePaymentTypeForBulkPay === "split_wallet_active"} optional*/}
      {/*              afterCheck={handleShowPayment}*/}
      {/*              name="split_wallet_active"*/}
      {/*              labelProp={`Customer Wallet (Balance: ${currencyFormatter(customer.account_balance)})`}/>*/}
      {/*    {split_wallet_active && (*/}
      {/*      <FlexColumnStyled>*/}
      {/*        <Input formatNumber label="Customer Wallet" onChange={handleChange} noBottomMargin className="fullWidth"*/}
      {/*               name="split_wallet_value"*/}
      {/*               type="number"*/}
      {/*               placeholder="Enter amount"/>*/}
      {/*        */}
      {/*        {Number(paymentValue.split_wallet_value) > Number(customer.account_balance) && (*/}
      {/*          <Paragraph size={1} color="error">Amount cannot be more than customer's wallet balance</Paragraph>*/}
      {/*        )}*/}
      {/*      </FlexColumnStyled>*/}
      {/*    )}*/}
      {/*  </FlexColumnStyled>*/}
      {/*)}*/}
      
      <FlexColumnStyled>
        <CheckBox checked={isBulkDebitPay && activePaymentTypeForBulkPay === "split_cash_active"} optional
                  afterCheck={handleShowPayment}
                  name="split_cash_active" labelProp="Cash"/>
        {split_cash_active && (
          <Input formatNumber label="Cash" onChange={handleChange} noBottomMargin className="fullWidth"
                 name="split_cash_value"
                 type="number"
                 placeholder="Enter amount"/>
        )}
      </FlexColumnStyled>
      
      <FlexColumnStyled>
        <CheckBox checked={isBulkDebitPay && activePaymentTypeForBulkPay === "split_transfer_active"} optional
                  afterCheck={handleShowPayment} name="split_transfer_active" labelProp="Transfer"/>
        {split_transfer_active && (
          <Fragment>
            <Input formatNumber label="Transfer" onChange={handleChange} noBottomMargin className="fullWidth"
                   name="split_transfer_value" type="number"
                   placeholder="Enter amount"/>
            <Select
              label="Select bank"
              name="split_transfer_bank"
              options={allBanks}
              displayKey="bankInfo"
              valueKey="id"
              displayName="display_transfer_bank"
              placeholder="Select bank to transfer"
            />
          </Fragment>
        )}
      </FlexColumnStyled>
      
      <FlexColumnStyled>
        <CheckBox checked={isBulkDebitPay && activePaymentTypeForBulkPay === "split_pos_active"} optional
                  afterCheck={handleShowPayment}
                  name="split_pos_active" labelProp="POS"/>
        {split_pos_active && (
          <Fragment>
            <Input formatNumber label="POS" onChange={handleChange} noBottomMargin className="fullWidth"
                   name="split_pos_value"
                   type="number"
                   placeholder="Enter amount"/>
            <Select
              label="Select bank"
              name="split_pos_bank"
              options={allBanks}
              displayKey="bankInfo"
              valueKey="id"
              displayName="display_pos_bank"
              placeholder="Select bank to transfer"
            />
          </Fragment>
        )}
      </FlexColumnStyled>
  
      <SpaceBetweenStyled className="summary">
        <Heading color="secondary">Amount Paying: </Heading>
        <Heading color="black">{currencyFormatter(getAmountPaying())}</Heading>
      </SpaceBetweenStyled>
  
      <SpaceBetweenStyled className="summary">
        <Heading color={(getAmountPaying() > Number(totalPayable) ? "error" : "secondary")}>
          Amount Remaining:
        </Heading>
        <Heading color={(getAmountPaying() > Number(totalPayable) ? "error" : "black")}>
          {currencyFormatter(Number(totalPayable) - getAmountPaying())}
        </Heading>
      </SpaceBetweenStyled>
  
      <SpaceBetweenStyled>
        <Heading color="secondary">Total Payable: </Heading>
        <Heading color="black">{currencyFormatter(totalPayable)}</Heading>
      </SpaceBetweenStyled>
    </SplitOrPartialPaymentStyled>
  )
}


export default SplitOrPartialPayment