export const settlementLogsRestructure = (data) => {
  const results = data?.results?.map(log => ({
    ...log,
    ...log.settlement_account,
    id: log.id,
    status: log?.status,
    remark: log?.remark,
    date: log?.date,
    settlement_id: log.id.split("-")[log.id.split("-").length - 1]
  }))
  
  return { ...data, results }
}

export const ovaloopTransferLogRestructure = (data) => {
  const results =  data.results.map((result) => ({
    ...result,
    sender_details: `${result.originator_details.source_account_name} (${result.originator_details.source_bank_name}})`
  }))

  return { ...data, results }
}


export const ovaloopTransactionRestructure = (data) => {
  return data?.map(transaction => {
    let ovaloopStatus = ""
    if(transaction.notificationStatus.includes("Successfully")) {
      ovaloopStatus = "confirmed"
    }
  
    if(transaction.notificationStatus.includes("Failed")) {
      ovaloopStatus = "failed"
    }
  
    if(transaction.notificationStatus.includes("Pending")) {
      ovaloopStatus = "pending"
    }
    return { ...transaction, ovaloopStatus }
  })
}