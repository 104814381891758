import {Outlet} from "react-router-dom";
import {Logo} from "../../assets/images";
import {useQueryClient} from "react-query";
import {useUserProfile} from "../../hooks/useServices/userHooks";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {ChartCircle, Logout, Routing, ScanBarcode, User} from "iconsax-react";
import {ContainerStyled, DashboardLayoutStyled} from "../../styles/DashboardStyles";
import UserNavigationProfile from "../../components/Card/UserNavigationProfile";
import {DashboardMobileNav, Image, Line, MenuList} from "../../components";
import {useBusinessByIdQuery} from "../../hooks/useServices/useStoreServices";
import {Heading, Heading2} from "../../styles/textStyles";
import useAllowOnlyAuthenticatedUsers from "../../hooks/useRouteProtection/useAllowOnlyAuthenticatedUsers";
import {clearAllDataInStorage, clearBusinessIdFromStorage, getBusinessAccountIdFromStorage} from "../../utils";
import {isPackageBasic, isPackageExpired, isTimeForPackageRenewal, isUserEmailVerified} from "../../utils/expiryCheck";
import {Fragment, useEffect, useState} from "react";
import NotificationDrawer from "../../components/Drawer/NotificationDrawer";
import {useModal} from "../../hooks";
import {useNotificationsQuery} from "../../hooks/useServices/useNotificationsServices";
import useNavigationMenuList from "../../hooks/useUtils/useNavigationMenuList";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";
import useSyncDesktopOfflineData from "../../hooks/useDesktop/useUtils/useSyncDesktopOfflineData";
import useDesktopOnlineStatusContext from "../../hooks/useContexts/useDesktopOnlineStatusContext";
import {desktopOnlineStatusReducerActions} from "../../reducers/DesktopOnlineStatusReducer";
import useDesktopOfflineModeAllowed from "../../hooks/useDesktop/useUtils/useDesktopOfflineModeAllowed";
import {onlyShowModulesBasedOnAccountsId, PRICE_CHECKER_MODULE_ACCOUNTS} from "../../utils/manageDisplayOfFeatures";


const DashboardLayout = () => {
  useAllowOnlyAuthenticatedUsers()
  const {isOfflineDataSyncing, onManualSync} = useSyncDesktopOfflineData()
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  
  const [setIsShowDownloadModal] = useState(!localStorage.getItem("showDownload"))
  const {mainDashboardNavMenuList} = useNavigationMenuList()
  
  const queryClient = useQueryClient()
  const {navigate, toast, isOffline} = useGlobalContext()
  const {desktopOnlineReducer} = useDesktopOnlineStatusContext()
  const {data: business} = useBusinessByIdQuery()
  const {data: notifications} = useNotificationsQuery()
  const {data: userProfile, isSuccess: isUserSuccess, error} = useUserProfile()
  
  const [isNotificationDrawer, setIsNotificationDrawer] = useModal()
  
  useEffect(() => {
    if (!!error) {
      clearAllDataInStorage()
      navigate("/login")
    }
  }, [error])
  
  const handleLogout = () => {
    desktopOnlineReducer({type: desktopOnlineStatusReducerActions.CLEAR_PRODUCT_TO_SYNC})
    desktopOnlineReducer({type: desktopOnlineStatusReducerActions.CLEAR_CUSTOMER_TO_SYNC})
  
    clearAllDataInStorage()
    queryClient.clear()
    navigate("/login")
  }

  const handleSwitchStore = () => {
    // handle ovaloop staff switch store
    if (userProfile?.[0]?.user_type === "staff") {
      queryClient.clear()
      navigate(`/staff_backend/accounts/${getBusinessAccountIdFromStorage()}`)
  
      return
    }
  
    if (userProfile?.[0]?.package_details?.package_name === "basic") {
      toast.error("Please upgrade your package to access this feature", "switch_store")
      return
    }
  
    desktopOnlineReducer({
      re_sync: false,
      type: desktopOnlineStatusReducerActions.RE_SYNC_ALL_DATA
    })
  
    clearBusinessIdFromStorage()
    queryClient.clear()
    navigate("/select-store")
  }

  const handleRenewPackage = () => {
    if (userProfile?.[0]?.user_type !== "client_admin" || isOffline) {
      toast.error("Please contact the store owner to renew package", "renew-package")
      return
    }
  
    toast.error("Please contact ovaloop tech support", "renew-package")
    // navigate("/dashboard/settings/subscription")
  }
  
  const handleEmailVerification = () => {
    if (isOffline) {
      toast.error("Please switch desktop online to verify your email", "verify_email")
      return
    }
  
    navigate("/dashboard/settings/verify_email")
  }
  
  const handleDesktopClose = (e) => {
    e.stopPropagation()
    setIsShowDownloadModal(false)
    localStorage.setItem("showDownload", "false")
  }
  
  const handleDesktopAppDownload = () => {
    window.open("https://www.ovaloop.com/#download")
  }
  
  const handleSwitchToPriceCheckerMode = () => {
    toast.confirm(
      "Are you sure you want to switch screen to price checker mode. (Press Ctrl + Shift + X to exit)",
      () => navigate("/full_screen_mode/price-checker")
    )
  }
  
  const handleManualSyncingOfOfflineData = () => {
    if (!isOfflineDataSyncing) {
      onManualSync()
      return
    }
    
    toast.info("Offline orders syncing now, please wait")
  }
  
  return (
    <DashboardLayoutStyled>
      <DashboardMobileNav businessName={business?.store_name} openNotification={setIsNotificationDrawer}/>
      {isNotificationDrawer && <NotificationDrawer data={notifications} closeDrawer={setIsNotificationDrawer}/>}
      
      <nav className="side_navigation">
        <div className="img_div">
          <Image src={Logo} alt="Ovaloop logo"/>
        </div>
        
        <div className="nav_menu_list">
          <div>
            {!userProfile?.[0]?.user_type && (
              <ContainerStyled padding>
                <TableSkeleton height={30} count={6}/>
              </ContainerStyled>
            )}
  
            {!!userProfile?.[0]?.user_type && (
              <Fragment>
                {userProfile?.[0]?.user_type === "staff" && (
                  <Fragment>
                    <MenuList ignorePermission title="Staff Backend" Icon={User} link={`/staff_backend`} />
                    <br/>
                  </Fragment>
                )}
  
                {(userProfile?.[0]?.user_type !== "client_admin" && userProfile?.[0]?.user_type !== "staff") && (
                  <MenuList ignorePermission title="My Dashboard" Icon={User} link={`/dashboard/staff_dashboard`} />
                )}
  
                {mainDashboardNavMenuList.map((item, k) => (
                  <MenuList isNew={item.isNew} key={k} item={item} title={item.title} Icon={item.icon} link={item.link} dropDown={item.dropDown} options={item.options} />
                ))}
              </Fragment>
            )}
          </div>

          <div className="nav_menu_settings">
            {!userProfile?.[0]?.user_type && (
              <ContainerStyled padding>
                <TableSkeleton height={30} count={1} />
              </ContainerStyled>
            )}
            
            {!!userProfile?.[0]?.user_type && (
              <Fragment>
                {(isPackageExpired(userProfile?.[0]?.package_details) && !isPackageBasic(userProfile)) && (
                  <div className="package_renewal_warning" onClick={handleRenewPackage}>
                    <Heading>Your package subscription has expired, <u>renew now</u></Heading>
                  </div>
                )}
  
                {(isTimeForPackageRenewal(userProfile?.[0]?.package_details) && !isPackageExpired(userProfile?.[0]?.package_details) && !isPackageBasic(userProfile)) && (
                  <div className="package_renewal_warning" onClick={handleRenewPackage}>
                    <Heading>Your package subscription is about to expire, <u>renew now</u></Heading>
                  </div>
                )}
  
                {(isUserSuccess && !isUserEmailVerified(userProfile)) && (
                  <div className="email_verification_warning" onClick={handleEmailVerification}>
                    <Heading>Your email is not verified, <u>verify now</u></Heading>
                  </div>
                )}
  
                {isDesktopOfflineModeAllowed && (
                  <div className="download_desktop" onClick={handleManualSyncingOfOfflineData}>
                    <Heading size={.85}>
                      <u>Click to sync all offline orders now</u>
                      {isOfflineDataSyncing && (
                        <ChartCircle className="loading_circle" size={18} color="white"/>
                      )}
                    </Heading>
                  </div>
                )}
  
                {/*{(isUserSuccess && isShowDownloadModal && !window?.require) && (*/}
                {/*  <div className="download_desktop" onClick={handleDesktopAppDownload}>*/}
                {/*    <Heading>*/}
                {/*      Download Our Desktop App*/}
                {/*      <CloseCircle color="white" onClick={handleDesktopClose} />*/}
                {/*    </Heading>*/}
                {/*  </div>*/}
                {/*)}*/}
  
                <Line/>
                {/*{(isUserSuccess && isPackageBasic(userProfile)) && (*/}
                {/*  <div className="upgrade_plan">*/}
                {/*    <Link to="/dashboard/settings/subscription"><Heading>Upgrade your plan</Heading></Link>*/}
                {/*  </div>*/}
                {/*)}*/}
  
                {onlyShowModulesBasedOnAccountsId(userProfile, PRICE_CHECKER_MODULE_ACCOUNTS) && (
                  <MenuList title="Price Checker Mode" isNew Icon={ScanBarcode}
                            onClick={handleSwitchToPriceCheckerMode}/>
                )}
  
                {!isOffline && (
                  <MenuList title="Store Front" isNew Icon={Routing}
                            onClick={() => navigate("/dashboard/settings/store_front")}/>
                )}
  
                {(userProfile?.[0]?.user_type === "client_admin") && (
                  <MenuList title="Switch Store" Icon={Routing} onClick={handleSwitchStore}/>
                )}
  
                {(userProfile?.[0]?.other_business?.length > 0 && userProfile?.[0]?.user_type !== "client_admin") && (
                  <MenuList title="Switch Store" Icon={Routing} onClick={handleSwitchStore} ignorePermission/>
                )}
  
                <MenuList title="Logout" Icon={Logout} onClick={handleLogout} ignorePermission/>
              </Fragment>
            )}
            <br/>
          </div>
        </div>
      </nav>

      <section>
        {/* Top Navigation */}
        <nav className="top_navigation">
          <Heading2 color="black">{business?.store_name}</Heading2>

          <div className="navigation_control">
            {isUserSuccess && (
              <UserNavigationProfile
                openNotification={setIsNotificationDrawer}
                firstName={userProfile?.[0]?.first_name}
                lastName={userProfile?.[0]?.last_name}
                companyName={userProfile?.[0]?.business_name || userProfile?.[0]?.business_details?.name || ""}
              />
            ) }
          </div>
        </nav>

        <main>
          <Outlet />
        </main>
      </section>
    </DashboardLayoutStyled>
  )
}


export default DashboardLayout