import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";
import {ovaloopTransferLogRestructure, settlementLogsRestructure} from "./ovaloopPaymentRestrucuture";


export const getSettlementBankServices = async () => {
	try {
		const { data } = await axios.get("/nip_banks/")
		return { banks: data?.data }
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const createSettlementAccountServices = async (businessId, body) => {
	try {
		const { data } = await axios.patch(`/settlement/?business_id=${businessId}`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const createBusinessReservedAccountServices = async (body) => {
	try {
		const { data } = await axios.post("/reserved_account/", body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}


export const createDynamicAccountService = async (body) => {
	try {
		const { data } = await axios.post("/vas/account/", { account_type: "dynamic", ...body })
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}


export const verifyDynamicPaymentService = async (queryParam) => {
	const url = handleQueryParams('/verify_payment', queryParam)
	
	try {
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const createReservedAccountService = async (businessId) => {
	try {
		const { data } = await axios.post("/vas/account/", { account_type: "reserved", business_id: businessId })
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const createCustomerReservedAccountService = async (businessId, customerId) => {
	try {
		const { data } = await axios.post("/vas/account/", { account_type: "reserved", customer_id: customerId, business_id: businessId })
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const ovaloopTransferLogsService = async (queryParams) => {
	const url = handleQueryParams("/payment_log", queryParams)
	
	try {
		const { data } = await axios.get(url)
		return ovaloopTransferLogRestructure(data)
	} catch (e) {
		throw e?.response?.data || ""
	}
}


export const getOvaloopPayTransactionWallet = async (businessId) => {
	try {
		const { data } = await axios.get(`/transaction_wallet/?business_id=${businessId}`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}


export const getSettlementRequestLogsService = async (queryParams) => {
	const url = handleQueryParams("/settlement_requests", queryParams)
	
	try {
		const { data } = await axios.get(url)
		return settlementLogsRestructure(data)
	} catch (e) {
		throw e?.response?.data || ""
	}
}


export const requestSettlementService = async (body) => {
	try {
		const { data } = await axios.post(`/pay_out/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}



// SUBSCRIPTION WALLET

export const getSubscriptionAccountServices = async () => {
	try {
		const { data } = await axios.get(`/account_wallet/`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const getSubscriptionAccountLogsServices = async (query = {}) => {
	try {
		const url = handleQueryParams(`/account_wallet_log`, query)
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const getPendingTransactionLogsServices = async (query = {}) => {
	try {
		const url = handleQueryParams(`/pending_transactions`, query)
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const createSubscriptionAccountServices = async (body) => {
	try {
		const { data } = await axios.post(`/create_account_wallet/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}