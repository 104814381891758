import {CloseSquare, Trash} from "iconsax-react";
import {Heading} from "../../../../styles/textStyles";
import {Button, CheckBox, Form, Input, Select} from "../../../index";
import {FlexStyled, SpaceBetweenStyled} from "../../../../styles/utilStyles";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {useAllStoresQuery} from "../../../../hooks/useServices/useStoreServices";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {Fragment, memo, useState} from "react";
import {useProductByIdQuery} from "../../../../hooks/useServices/useProductServices";
import {useFormikContext} from "formik";
import uuid from "react-uuid";
import {useParams} from "react-router-dom";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";


const DistributeProductModal = ({ onClose, handleSubmit, mutation }) => {
  const { productId } = useParams()
  
  const { getBusinessId } = useGlobalContext()
  const businessId = getBusinessId()
  const { isSuccess, data: allStores } = useAllStoresQuery()
  const { data: product } = useProductByIdQuery(productId)
  const { currencyFormatter, numberFormatter, dateOnlyFormatter } = useDataTypeFormatter()
  const [productMeasurements] = useState([
    {name: product.unit_measurement, base_quantity: 1, selling_price: product.selling_price},
    ...(product?.meta_measurement || [])
  ])
  
  const handleSubmission = (values) => {
    const formValues = handleCostPriceDataFormatting(values, {...product, meta_measurement: productMeasurements})
    handleSubmit(formValues)
  }
  
  const moveStores = (stores = []) => {
    return stores.filter(store => store.id !== businessId)
  }
  
  const costPricesArray = product?.cost_prices?.map(cost_price => ({
    ...cost_price,
    name: `${currencyFormatter(cost_price.cost_price)} [${numberFormatter(cost_price.qty_remaining)} qty] ${(!!cost_price.expiry_date && cost_price.expiry_date !== "None") ? `- Expiry: ${dateOnlyFormatter(cost_price.expiry_date)}` : ""} `
  }))
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Distribute Product</Heading>

        <Form values={{ qty: "", business: "", display_store_name: "" }} onSubmit={handleSubmission}>
          {!!costPricesArray && costPricesArray?.length > 0 && (
            <AddCostPriceToMove product={{...product, meta_measurement: productMeasurements}}
                                cost_prices={costPricesArray}/>
          )}
  
          {(!costPricesArray || (!!costPricesArray && costPricesArray?.length < 1)) && (
            <Fragment>
              
              {(product?.meta_measurement && product?.meta_measurement?.length > 0) && (
                <Select
                  required
                  valueKey="name"
                  name="measurement_unit"
                  label="Select measurement unit"
                  displayKey="name"
                  displayName="measurement_name"
                  options={productMeasurements || []}
                  placeholder="Select measurement to move"
                />
              )}
              
              <Input type="number" name="qty" className="fullWidth" label="Quantity"
                     placeholder="Enter quantity of measurement"/>
            </Fragment>
          )}
          
          <Select
            required
            valueKey="id"
            name="business"
            label="Select Store"
            className="fullWidth"
            displayKey="store_name"
            displayName="display_store_name"
            options={isSuccess && moveStores(allStores)}
            placeholder="Select store to move products to"
          />
  
          <CheckBox name="move_sp" labelProp="Move product selling price to store?" optional/>
  
          <FlexStyled>
            <Button type="submit" text="Distribute" isLoading={mutation.isLoading}/>
          </FlexStyled>
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}


export default DistributeProductModal


export const AddCostPriceToMove = memo(({cost_prices = [], product = {}, isAdjustCost}) => {
  const [selectedCostPrices, setSelectedCostPrices] = useState([])
  const {setFieldValue, values} = useFormikContext()
  
  const handleCostPriceSelect = (data) => {
    setSelectedCostPrices([...selectedCostPrices, data.id])
  }
  
  const SalesAttachmentForm = () => {
    const id = uuid()
    
    const SelectComp = () => (
      <Select
        valueKey="id"
        displayKey="name"
        name={`sales_attachment_name_${id}`}
        label="Product Cost Price"
        displayName={`sales_attachment_display_${id}`}
        placeholder="Select product cost price"
        options={cost_prices.filter(price => !selectedCostPrices.includes(price.id))}
        updateFn={handleCostPriceSelect}
      />
    )
    
    const SelectMeasurement = ({product}) => {
      return (
        <Select
          valueKey="name"
          name={`sales_attachment_measurement_${id}`}
          label="Select measurement unit"
          displayKey="name"
          displayName={`measurement_name_${id}`}
          options={product?.meta_measurement || []}
          placeholder="Select measurement to move"
        />
      )
    }
    
    const InputComp = ({measurement}) => (
      <Input
        type="number"
        formatNumber
        name={`sales_attachment_quantity_${id}`}
        label={isAdjustCost ? "Cost Price" : `Quantity in ${measurement || ""}`}
        placeholder={isAdjustCost ? "Enter a new cost price" : "Enter quantity"}
      />
    )
    
    return {id, SelectComp, InputComp, SelectMeasurement}
  }
  
  const [moreProducts, setMoreProducts] = useState([])
  
  const addExtraProduct = () => {
    setMoreProducts([...moreProducts, SalesAttachmentForm()])
  }
  
  const handleDelete = (id) => {
    const newProducts = moreProducts.filter(products => products.id !== id)
    setMoreProducts(newProducts)
    
    setFieldValue(`sales_attachment_name_${id}`, "")
    setFieldValue(`sales_attachment_display_${id}`, "")
    setFieldValue(`sales_attachment_quantity_${id}`, "")
    setFieldValue(`sales_attachment_cost_${id}`, "")
  }
  
  return (
    <div className="fullWidth">
      <Fragment>
        <FlexStyled className="fullWidth">
          <Select
            valueKey="id"
            displayKey="name"
            name="sales_attachment"
            label="Product Cost Price"
            displayName="sales_attachment_display"
            placeholder="Select product cost price"
            options={cost_prices}
            updateFn={handleCostPriceSelect}
          />
          
          {(product?.meta_measurement && product?.meta_measurement?.length > 0) && (
            <Select
              required
              valueKey="name"
              name="measurement_unit"
              label="Select measurement unit"
              displayKey="name"
              displayName="measurement_name"
              options={product?.meta_measurement || []}
              placeholder="Select measurement to move"
            />
          )}
  
          <Input
            type="number"
            formatNumber
            name="sales_attachment_quantity"
            label={isAdjustCost ? "Cost Price" : `Quantity in ${values["measurement_name"] || ""}`}
            placeholder={isAdjustCost ? "Enter a new cost price" : "Enter quantity"}
          />
        </FlexStyled>
        
        {moreProducts?.map(({id, SelectComp, InputComp, SelectMeasurement}) => (
          <SpaceBetweenStyled className="fullWidth" key={id}>
            <SelectComp />
            <SelectMeasurement product={product}/>
            <InputComp />
            <Trash size={20} color="red" className="supplier_delete" onClick={handleDelete.bind(this, id)} />
          </SpaceBetweenStyled>
        ))}
  
        {cost_prices.length !== selectedCostPrices.length && (
          <Button onClick={addExtraProduct} type="button" text="Select product batch" className="small" small />
        )}
        <br/>
      </Fragment>
    
    </div>
  )
})


export const handleCostPriceDataFormatting = (values, product) => {
  let totalQuantity = 0
  const formValues = { ...values }
  let {sales_attachment, sales_attachment_quantity} = values
  
  if(!sales_attachment) {
    return { ...formValues }
  }
  
  if (values?.measurement_unit) {
    const measurement = product?.meta_measurement?.find(measurement => measurement.name === values?.measurement_unit)
    sales_attachment_quantity = measurement?.base_quantity * sales_attachment_quantity
  }
  
  totalQuantity += Number(sales_attachment_quantity)
  const products = [
    { id: sales_attachment, quantity: Number(sales_attachment_quantity) }
  ]
  
  delete formValues?.sales_quantity
  delete formValues?.sales_attachment
  delete formValues?.sales_attachment_quantity
  delete formValues?.sales_attachment_cost
  delete formValues?.sales_attachment_display
  delete formValues?.sales_attachment_cost_format
  delete formValues?.sales_attachment_quantity_format
  
  
  for (const key in formValues) {
    if(key.slice(0, 21) === "sales_attachment_name") {
      
      const uuid = key.split("_")[3]
      
      const id = formValues[`sales_attachment_name_${uuid}`]
      let quantity = Number(formValues[`sales_attachment_quantity_${uuid}`])
      
      if (formValues[`sales_attachment_measurement_${uuid}`]) {
        const measurement = product?.meta_measurement?.find(measurement => measurement.name === formValues[`sales_attachment_measurement_${uuid}`])
        quantity = measurement?.base_quantity * quantity
      }
      
      products.push({ id, quantity })
      totalQuantity += quantity || 0
      
      delete formValues[`sales_attachment_name_${uuid}`]
      delete formValues[`sales_attachment_display_${uuid}`]
      delete formValues[`sales_attachment_quantity_${uuid}`]
      delete formValues[`sales_attachment_cost_${uuid}`]
      delete formValues[`sales_attachment_quantity_${uuid}_format`]
      delete formValues[`sales_attachment_cost_${uuid}_format`]
    }
  }

  return { ...formValues, cost_price_ids: products, qty: totalQuantity }
}