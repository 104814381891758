import {
  Chips,
  CreateSettlementAccountModal,
  CreditCard,
  PageHeaderWithButton,
  ScreenLoading,
  Tab,
  Table,
  TableItemWithStatus,
  TablePagination,
  ViewSettlementModal,
  ViewTransferModal,
  WalletDetailsCard,
  WithdrawModal
} from "../../../components";
import {WalletImage} from "../../../assets/images";
import {Fragment, useState} from "react";
import {FlexStyled, PageSummaryStyled, SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {ContainerStyled} from "../../../styles/DashboardStyles";
import {
  ovaloopPayQueryKeys,
  useCreateBusinessReservedAccountMutation,
  useCreateSettlementAccountMutation,
  useOvaloopTransferLogQuery,
  usePendingTransactionLogsQuery,
  useRequestSettlementMutation,
  useSettlementRequestLogsQuery,
  useTransactionWalletQuery
} from "../../../hooks/useServices/useOvaloopPaymentServices";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import DateFilter from "../../../components/Utils/DateFilter";
import {Bank, MoneySend} from "iconsax-react";
import {useModal} from "../../../hooks";
import {useBusinessByIdQuery} from "../../../hooks/useServices/useStoreServices";
import {
  ovaloopTransferLogRestructure,
  settlementLogsRestructure
} from "../../../services/ovaloopPaymentServices/ovaloopPaymentRestrucuture";
import {SetUpLayoutStyled} from "../setUp/SetUpStyles";
import OvaloopPayTransactionModal from "../../../components/Modal/OvaloopPayModal/OvaloopPayTransactionModal";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";


const TABS = ["Transfer Logs", "Settlement Logs", "Pending Transactions"]

const OvaloopPay = () => {
  const [activeTab, setActiveTab] = useState(TABS[0])
  const { nairaCurrencyFormatter } = useDataTypeFormatter()
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useModal()
  const [isSettlementModalOpen, setIsSettlementModalOpen] = useModal()
  
  
  const { data: business } = useBusinessByIdQuery()
  const { debounceState } = useQueryDebounce()
  const { mainQuery, ...transactionWallet } = useSearchQuery(debounceState, useTransactionWalletQuery, true)
  const { data: pendingTransactions, refetch: refetchPendingTransactions, isLoading: isPendingTransactionsLoading } = usePendingTransactionLogsQuery()
  
  const requestSettlementMutation = useRequestSettlementMutation({ successFn: setIsWithdrawModalOpen })
  const createSettlementAccountMutation = useCreateSettlementAccountMutation({ successFn: setIsSettlementModalOpen })
  const createBusinessReservedAccountMutation = useCreateBusinessReservedAccountMutation({ successFn: () => {} })
  
  
  const handleWithdraw = (formValues) => {
    const settlement_account_id = business.settlement_account.id
    requestSettlementMutation.mutate({ ...formValues, settlement_account_id })
  }
  
  const handleCreateSettlementAccount = (formValues) => {
    createSettlementAccountMutation.mutate(formValues)
  }
  
  const handleCreateReservedAccount = () => {
    createBusinessReservedAccountMutation.mutate()
  }
  
  const getPageHeaderButtonClick = () => {
    if(!business?.ovaloop_account?.account_number) {
      return handleCreateReservedAccount
    }
  
    if(business?.settlement_account?.bank_name && business?.settlement_account?.status === "rejected") {
      return setIsSettlementModalOpen
    }
    
    if(!!business?.ovaloop_account?.account_number && !business?.settlement_account?.bank_name) {
      return setIsSettlementModalOpen
    }
    
    return setIsWithdrawModalOpen
  }
  
  const isPageHeaderButtonLoading = () => {
    if(!business?.ovaloop_account?.account_number) {
      return createBusinessReservedAccountMutation.isLoading
    }
    
    if(business?.settlement_account?.bank_name && business?.settlement_account?.status === "rejected") {
      return createSettlementAccountMutation.isLoading
    }
    
    if(!!business?.ovaloop_account?.account_number && !business?.settlement_account?.bank_name) {
      return createSettlementAccountMutation.isLoading
    }
    
    return requestSettlementMutation.isLoading
  }
  
  const getPageHeaderButtonText = () => {
    if(!business?.ovaloop_account?.account_number) {
      return "Generate Business Account"
    }
  
    if(business?.settlement_account?.bank_name && business?.settlement_account?.status === "rejected") {
      return "Update settlement details"
    }
  
    return "Withdraw"
  }
  
  const getPageHeaderButtonIcon = () => {
    if(!business?.ovaloop_account?.account_number) {
      return Bank
    }
  
    if(business?.settlement_account?.bank_name && business?.settlement_account?.status === "rejected") {
      return Bank
    }
    
    return MoneySend
  }
  
  
  return (
    <ContainerStyled>
      <PageHeaderWithButton
        title="Ovaloop Pay"
        isLoading={isPageHeaderButtonLoading()}
        buttonDisabled={business?.settlement_account?.bank_name && !business?.settlement_account?.is_verified && business?.settlement_account?.status !== "rejected"}
        buttonText={getPageHeaderButtonText()}
        Icon={getPageHeaderButtonIcon()}
        buttonClick={getPageHeaderButtonClick()}
      />
  
      {isWithdrawModalOpen && <WithdrawModal onClose={setIsWithdrawModalOpen} handleSubmit={handleWithdraw} mutation={requestSettlementMutation} />}
      {isSettlementModalOpen && <CreateSettlementAccountModal onClose={setIsSettlementModalOpen} handleSubmit={handleCreateSettlementAccount} mutation={createSettlementAccountMutation} />}

      
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={transactionWallet.refetch} />
      )}

      <Fragment>
        <PageSummaryStyled>
          {mainQuery.isSuccess && (
            <Fragment>
              <CreditCard name={transactionWallet?.data?.[0]?.account_name} number={transactionWallet?.data?.[0]?.account_number} balance={nairaCurrencyFormatter(transactionWallet?.data?.[0]?.amount || 0)} />
              <CreditCard isLoading={isPendingTransactionsLoading} handleReload={refetchPendingTransactions} name="Pending Transactions" balance={nairaCurrencyFormatter(pendingTransactions?.balance || 0)} totalPendingTransactions={pendingTransactions?.transactions?.length || 0} isPendingTransactions />
  
              {business?.settlement_account?.bank_name && (
                <WalletDetailsCard settlement_account={business?.settlement_account} icon={WalletImage} />
              )}
            </Fragment>
          )}
  
          {transactionWallet.isQueryLoading && (
            <Fragment>
              <TableSkeleton count={1} height={160} width="300px" noTop />
              <TableSkeleton count={1} height={160} width="300px" noTop />
            </Fragment>
          )}
          
          {/*<WalletDetailsCard title="Locked Amount" value={nairaCurrencyFormatter(0)} icon={LockedImage} />*/}
        </PageSummaryStyled>

        <br/>
        <SectionStyled>
          <Tab tabs={TABS} activeTab={activeTab} setActiveTab={setActiveTab} />

          {activeTab === "Transfer Logs" && <OvaloopTransferLogs />}

          {activeTab === "Settlement Logs" && <OvaloopSettlementLogs />}
  
          {activeTab === "Pending Transactions" && <PendingTransactionsLogs />}
        </SectionStyled>
      </Fragment>
    </ContainerStyled>
  )
}

export default OvaloopPay;


export const OvaloopTransferLogs = () => {
  const { debounceState } = useQueryDebounce()
  
  const [isViewModal, setIsViewModal] = useModal()
  const [viewLog, setViewLog] = useState({})
  
  const { transferLogTable } = tableHeadersAndValueKeys
  const [transactionType, setTransactionType] = useState("")
  
  const {getBusinessId} = useGlobalContext()
  const [{ from_date, to_date }, setDateFilter] = useState({ from_date: "", to_date: "" })
  const { ...allTransactionLogs } = useSearchQuery(debounceState, useOvaloopTransferLogQuery.bind(this, { status: transactionType, from_date, to_date }))
  
  const chips = [{ name: "Confirmed", value: "verified" }, { name: "Pending", value: "pending" }]
  
  const handleViewLog = (log) => {
    setViewLog(log)
    setIsViewModal()
  }
  
  return (
    <Fragment>
      {isViewModal && <ViewTransferModal onClose={setIsViewModal} formValues={viewLog} />}
      
      <SpaceBetweenStyled>
        <FlexStyled>
          {chips.map(item => (
            <Chips
              key={item.name}
              title={item.name}
              active={item.value === transactionType}
              onClick={() => setTransactionType(item.value)}
            />
          ))}
        </FlexStyled>
    
        <DateFilter placeholder="Filter by date" setDateFilter={setDateFilter} />
      </SpaceBetweenStyled>
      <br />
      
      <SetUpLayoutStyled>
        <Table headers={transferLogTable.headers} noCols={transferLogTable.headers.length}>
          {allTransactionLogs.isQueryWithData && (
            allTransactionLogs?.data?.results.map((log, k) => (
              <TableItemWithStatus
                count={k}
                onView={handleViewLog}
                pageCount={allTransactionLogs.currentCount}
                key={k}
                data={log}
                statusPosition={4}
                keys={transferLogTable.values}
              />
            ))
          )}
    
          {allTransactionLogs.isQueryWithNoData && (
            <NoQueryData text="No payment log" />
          )}
    
          {allTransactionLogs.isQueryLoading && (
            <TableSkeleton />
          )}
        </Table>
  
        {allTransactionLogs.showPagination && (
          <TablePagination
            query={allTransactionLogs}
            queryKey={ovaloopPayQueryKeys.transferLogs(getAllObjectKeysWithValues({
                from_date,
                to_date,
                status: transactionType,
                businessId: getBusinessId()
              })
            )} restructureFn={ovaloopTransferLogRestructure}
          />
        )}
      </SetUpLayoutStyled>
  
      {allTransactionLogs.showPagination && (
        <TablePagination
          query={allTransactionLogs}
          queryKey={ovaloopPayQueryKeys.transferLogs(getAllObjectKeysWithValues({
              from_date,
              to_date,
              status: transactionType,
              businessId: getBusinessId()
            })
          )}
          restructureFn={ovaloopTransferLogRestructure}
        />
      )}
    </Fragment>
  )
}


export const OvaloopSettlementLogs = () => {
  const { debounceState } = useQueryDebounce()
  const { settlementLogTable } = tableHeadersAndValueKeys
  const [transactionType, setTransactionType] = useState("")
  
  const {getBusinessId} = useGlobalContext()
  const [{ from_date, to_date }, setDateFilter] = useState({ from_date: "", to_date: "" })
  const { ...settlementRequestLogs } = useSearchQuery(debounceState, useSettlementRequestLogsQuery.bind(this, { status: transactionType, from_date, to_date }))
  
  const chips = [{ name: "Confirmed", value: "confirmed" }, { name: "Pending", value: "pending" }]
  
  const [isSettlePreviewOpen, setIsSettlePreviewOpen] = useModal()
  const [previewSettlementValues, setPreviewSettlementValues] = useState({})
  
  const handleViewSettlement = (settlement) => {
    setPreviewSettlementValues(settlement)
    setIsSettlePreviewOpen()
  }
  
  return (
    <Fragment>
      {isSettlePreviewOpen && <ViewSettlementModal handleSubmit={() => {}} mutation={{}} onClose={setIsSettlePreviewOpen} formValues={previewSettlementValues} />}
  
      <SpaceBetweenStyled>
        <FlexStyled>
          {chips.map(item => (
            <Chips
              key={item.name}
              title={item.name}
              active={item.value === transactionType}
              onClick={() => setTransactionType(item.value)}
            />
          ))}
        </FlexStyled>
    
        <DateFilter placeholder="Filter by date" setDateFilter={setDateFilter} />
      </SpaceBetweenStyled>
      <br/>
      
      <SetUpLayoutStyled>
        <Table headers={settlementLogTable.headers} noCols={settlementLogTable.headers.length}>
          {settlementRequestLogs.isQueryWithData && (
            settlementRequestLogs?.data?.results.map((log, k) => (
              <TableItemWithStatus
                count={k}
                onView={handleViewSettlement}
                pageCount={settlementRequestLogs.currentCount}
                key={k}
                data={log}
                statusPosition={4}
                keys={settlementLogTable.values}
              />
            ))
          )}
    
          {settlementRequestLogs.isQueryWithNoData && (
            <NoQueryData text="No settlement log" />
          )}
    
          {settlementRequestLogs.isQueryLoading && (
            <TableSkeleton />
          )}
        </Table>
  
        {settlementRequestLogs.showPagination && (
          <TablePagination
            query={settlementRequestLogs}
            restructureFn={settlementLogsRestructure}
            queryKey={ovaloopPayQueryKeys.settlementRequestLogs(getAllObjectKeysWithValues({
                from_date,
                to_date,
                status: transactionType,
                businessId: getBusinessId()
              })
            )}
          />
        )}
      </SetUpLayoutStyled>
      {settlementRequestLogs.showPagination && (
        <TablePagination
          query={settlementRequestLogs}
          restructureFn={settlementLogsRestructure}
          queryKey={ovaloopPayQueryKeys.settlementRequestLogs(getAllObjectKeysWithValues({
              from_date,
              to_date,
              status: transactionType,
              businessId: getBusinessId()
            })
          )}/>
      )}
      
    </Fragment>
  )
}



export const PendingTransactionsLogs = () => {
  const [isViewModal, setIsViewModal] = useModal()
  const [viewLog, setViewLog] = useState({})
  
  const { ovaloopPayTransactionTable } = tableHeadersAndValueKeys
  
  const [{ from_date, to_date }, setDateFilter] = useState({ from_date: "", to_date: "" })
  const { data: allPendingTransactions, isSuccess, isLoading } = usePendingTransactionLogsQuery({ from_date, to_date })
  
  const handleViewLog = (log) => {
    setViewLog(log)
    setIsViewModal()
  }
  
  return (
    <Fragment>
      {isViewModal &&
        <OvaloopPayTransactionModal
          formValues={viewLog}
        />
      }
      
      <SpaceBetweenStyled>
        <p></p>
        <DateFilter placeholder="Filter by date" setDateFilter={setDateFilter} />
      </SpaceBetweenStyled>
      <br />
      
      <SetUpLayoutStyled>
        <Table headers={ovaloopPayTransactionTable.headers} noCols={ovaloopPayTransactionTable.headers.length}>
          {(isSuccess && allPendingTransactions?.transactions.length > 0) && (
            allPendingTransactions?.transactions?.map((log, k) => (
              <TableItemWithStatus
                count={k}
                onView={handleViewLog}
                pageCount={1}
                key={k}
                data={{ ...log, ovaloopStatus: "pending" }}
                statusPosition={3}
                keys={ovaloopPayTransactionTable.values}
              />
            ))
          )}
          
          {(isSuccess && allPendingTransactions?.transactions.length === 0) && (
            <NoQueryData text="No pending transactions" />
          )}
          
          {isLoading && (
            <TableSkeleton />
          )}
        </Table>
      </SetUpLayoutStyled>
    </Fragment>
  )
}