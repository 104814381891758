import styled from "styled-components";
import {FlexStyled} from "./utilStyles";
import {ButtonStyled} from "./formStyles";
import {Heading} from "./textStyles";

console.log('')
const ConfirmationButtonStyled = styled(FlexStyled)`
    gap: .5rem;
    right: -1rem;
    margin-bottom: -.5rem;
    position: relative;
    margin-top: 1rem;
    justify-content: flex-end;

    ${ButtonStyled} {
        padding: .2rem;
        font-size: .7rem;
        width: 27%;
        border-radius: 0;
    }

    ${ButtonStyled}.cancel {
        background: white !important;
        color: ${props => props.theme.black} !important;
        border: 1px solid ${props => props.theme.gray} !important;
    }
`

const ConfirmationAlertBoxStyled = styled("div")`
    top: -.5rem;
    position: relative;

    svg {
        bottom: -.1rem;
        position: relative;
    }

    ${FlexStyled}:first-of-type {
        gap: .5rem;
        margin-bottom: 1.5rem;
    }

    ${Heading}.message {
        line-height: 1.6rem;
    }
`

export {ConfirmationAlertBoxStyled, ConfirmationButtonStyled}