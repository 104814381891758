import {formatDatetimeForBackend} from "../../../utils/desktopUtils/desktopSyncUtils";
import {handleSplitOrPartialPaymentMethod} from "../../../utils/formatForms/checkout";
import useGlobalContext from "../../useContexts/useGlobalContext";

const useSplitOrPartialPaymentValidation = () => {
  const {toast} = useGlobalContext()
  
  const validateSplitOrPartialPayment = ({values, totalAmount, customer}) => {
    values.pay_mode = "split"
    
    const total = Number(Number(totalAmount).toFixed(2))
    
    if (Number(values.splitOrPartialPaymentAmount) <= 0) {
      toast.error("Partial amount cannot be less than or equal to 0")
      return null
    }
    
    if (Number(Number(values.splitOrPartialPaymentAmount).toFixed()) > total) {
      toast.error("Partial amount cannot be greater than total payable")
      return null
    }
    
    const isCustomerWalletUsed = Number(values.split_wallet_value || 0) > 0
    
    if (isCustomerWalletUsed && (!customer || !customer?.id)) {
      toast.error("Customer is required to use wallet")
      return null
    }
    
    if (isCustomerWalletUsed && Number(values.split_wallet_value) > Number(customer.account_balance)) {
      toast.error("Wallet amount cannot be greater than account balance")
      return null
    }
    
    const newValues = {
      ...values,
      pay_mode: "partial",
      ...(!!values.payment_date && {payment_date: formatDatetimeForBackend(values.payment_date)})
    }
    
    const {split_payment, ...otherValues} = handleSplitOrPartialPaymentMethod(newValues)
    
    for (const key in split_payment) {
      if (split_payment[key].amount < 0) {
        toast.error(`${split_payment[key].method} amount cannot be less than or equal to 0`)
        return null
      }
    }
    
    return {...otherValues, split_payment}
  }
  return {validateSplitOrPartialPayment}
}

export default useSplitOrPartialPaymentValidation